// import 'jquery-ui';

/**
 * Global Components Module Loader
 */

// Init components
var AFAComponents = function () {
    // Public methods
    return {
        init: function () {
            AFApp.init();
        }
    }	
}();

// On document ready
if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", function() {
		AFAComponents.init();
	});
 } else {
	AFAComponents.init();
 }

 // Init page loader
window.addEventListener("load", function() {
    AFApp.hidePageLoading();
});

// Declare KTApp for Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
	window.AFAComponents = module.exports = AFAComponents;
}