// Keenthemes' plugins
window.AFApp = require('@/src/js/components/app.js');
window.AFAComponents = require('@/src/js/components/_init.js');
// window.KTUtil = require('@/src/js/components/util.js');

// window.KTEventHandler = require('@/src/js/components/event-handler.js');
// window.KTBlockUI = require('@/src/js/components/blockui.js');
// window.KTCookie = require('@/src/js/components/cookie.js');
// window.KTDialer = require('@/src/js/components/dialer.js');
// window.KTDrawer = require('@/src/js/components/drawer.js');
// window.KTFeedback = require('@/src/js/components/feedback.js');
// window.KTImageInput = require('@/src/js/components/image-input.js');
// window.KTMenu = require('@/src/js/components/menu.js');
// window.KTPasswordMeter = require('@/src/js/components/password-meter.js');
// window.KTScroll = require('@/src/js/components/scroll.js');
// window.KTScrolltop = require('@/src/js/components/scrolltop.js');
// window.KTSearch = require('@/src/js/components/search.js');
// window.KTStepper = require('@/src/js/components/stepper.js');
// window.KTSticky = require('@/src/js/components/sticky.js');
// window.KTSwapper = require('@/src/js/components/swapper.js');
// window.KTToggle = require('@/src/js/components/toggle.js');

// Layout base js
// window.KTAppSidebar = require('@/src/js/layout/sidebar.js');
// window.KTLayoutSearch = require('@/src/js/layout/search.js');
// window.KTLayoutToolbar = require('@/src/js/layout/toolbar.js');
// window.KTThemeMode = require('@/src/js/layout/theme-mode.js');
// window.KTThemeModeUser = require('@/src/js/layout/theme-mode-user.js');